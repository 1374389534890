/*
 * Sidebar
 */

/* Hide for mobile, show later */
.sidebar {
    display: none;
  }
  @media (min-width: 768px) {
    .sidebar {
      position: fixed;
      top: 51px;
      bottom: 0;
      left: 0;
      z-index: 1000;
      display: block;
      padding: 20px;
      overflow-x: hidden;
      overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
      background-color: #f5f5f5;
      border-right: 1px solid #eee;
    }
  }
  
  /* Sidebar navigation */
  .nav-sidebar {
    margin-right: -21px; /* 20px padding + 1px border */
    margin-bottom: 20px;
    margin-left: -20px;
  }
  
  .nav-sidebar > li > ul {
    list-style: none;
  }
  .nav-sidebar > li > ul > li > a {
    padding: 5px 20px 5px 40px;
  }
  
  .nav > li > h4 {
    padding: 0 15px;
    margin: 0;
  }
  
  .nav-sidebar > li > a, .nav-sidebar > li > strong {
    padding-right: 20px;
    padding-left: 20px;
  }
  
  .nav-sidebar > .active > a,
  .nav-sidebar > .active > a:hover,
  .nav-sidebar > .active > a:focus {
    color: #fff;
    background-color: #428bca;
  }
  
  /*
   * Main content
   */
  
  .main {
    padding: 20px;
  }
  @media (min-width: 768px) {
    .main {
      padding-right: 40px;
      padding-left: 40px;
    }
  }
  .main .page-header {
    margin-top: 0;
  }
  
  .timeout-container{
    z-index: 1000;
    background: #ffffff;
    position: fixed;
    bottom: 15px;
    right: 15px;
    border: 1px solid #ccc;
    padding: 5px 15px;
    border-radius: 10px;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.5);
    width: 125px;
    text-align: center;
  }

  .timeout-container .timeout-counter{
    font-size: 25px;
    font-weight: 500;
  }
  
  .overlay{
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
    z-index: 100;
  }
  
  .overlay .overlay-text-box{
    width: 400px;
    height: 150px;
    position: absolute;
    left: 50%;
    margin-left: -200px;
    top: 50%;
    margin-top: -75px;
    background:white;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.5);
  }
  
  .dashboard-user-info{
    font-size: x-small;
  }
  