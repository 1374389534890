.client-detail-info-display {
    height: 300px;
    overflow: auto;
  }

.client-detail-info-display table tbody tr:first-child td, th{
    border-top: none;
}

.notes-container {
  border: 1px solid #ddd;
}

.division-status {
  border-bottom: 1px solid #ddd;
  padding: 15px;
}