.box--vertical-padding-md {
    margin: 2rem 0
}

.box--alignment-center {
    text-align: center;
}

.box--inline {
    display: inline;
}