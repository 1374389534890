.notes-container {
  border: 1px solid #ddd;
}

.note-list {
  height: 400px;
  overflow: auto;
}

.note-list.sm {
  height: 300px;
}

.note-list-attachment-header {
  height: 30px;
  border-top: 2px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.note-list-attachment-header b {
  line-height: 30px;
}

.note-list-attachment-list {
  height: 70px;
  overflow-y: auto;
}

.note-item .note-item-note {
  padding: 15px;
}

.note-item .meta {
  font-size: smaller;
  font-style: italic;
  padding: 5px 15px;
  background: #f9f9f9;
}

.note-input {
  padding: 15px;
  background: #f9f9f9;
  border-top: 1px solid #dddddd;
}

.note-remove {
  color: #bbbbbb;
  cursor: pointer;
  margin-right: 20px;
}

.note-remove i {
  font-size: smaller;
}

.note-remove:hover {
  color: #777777;
}

.note {
  cursor: pointer;
}


/* RICH TEXT */

h1 small {
  font-size: 16px;
}

.ql-size-small {
  font-size: 0.75em;
}

.ql-size-large {
  font-size: 1.5em;
}

.ql-size-huge {
  font-size: 2.5em;
}

.ql-font-serif {
  font-family: Georgia, Times New Roman, serif;
}

.ql-font-monospace {
  font-family: Monaco, Courier New, monospace;
}

.ql-container {
  font-family: 'Times New Roman', Times, serif;
  font-size: 14px;
}