.drop-container {
    border: 5px dashed #dddddd;
    position: absolute;
    top: 10px;
    left: 10px;
    bottom: 10px;
    right: 10px;
    z-index: 1000;
    background: rgba(255,255,255, 0.5);
  }
  