.itinto-modal {
    display: flex;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 2000;
  }
  
  .itinto-modal__background {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 2000;
  }
  
  .itinto-modal__content-container {
    width: 600px;
    margin: auto;
    position: relative;
    background: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
  }

  .itinto-modal__content-container.fullscreen {
    width: 100%;
    height: 100%;
  }
  
  .itinto-modal__header {
    font-weight: 400;
    font-size: 18px;
    border-bottom: 1px solid #ddd;
    padding: 10px;
  }
  
  .itinto-modal__footer {
    padding: 10px;
  }
  
  .itinto-modal__content {
    padding: 10px;
    height: 300px;
    overflow-y: scroll;
    flex: 1;
  }
  