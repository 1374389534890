/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body {
  padding-top: 50px;
}

.footer {
  height: 75px;
}

.pointer {
  cursor: pointer;
}

#root {
  padding-bottom: 4rem;
}

.loading {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  left: 0;
  display: table;
  z-index: 1000;
}

.loading > .loading-content {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

.loading > .loading-content > img {
  width: 40px
}

.loading > .loading-content > .text {
  margin-top: 15px;
}

.margin-top {
  margin-top: 10px;
}

.margin-top-lg {
  margin-top: 20px;
}

.d-table {
  display: table;
  width: 100%;
  height: 100%;
}

.d-table.d-table-cell {
  display: table-cell;
}

.d-table.d-table-row {
  display: table-row;
}

.v-align-mid {
  vertical-align: middle;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.drop-container {
  border: 5px dashed #dddddd;
  position: absolute;
  top: 10px;
  left: 10px;
  bottom: 10px;
  right: 10px;
  z-index: 1000;
  background: rgba(255,255,255, 0.5);
}

.display--flex{
  display: flex;
}

.align-items--center {
  align-items: center;
}

.table.two-column-table td {
    text-align: right;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.row.row-gutter {
  margin-top: 20px
}

.version-text {
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 5px;
}

/* give some space between inline buttons */
.btn + .btn {
  margin-left: 10px;
}