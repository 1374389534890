.drop-down-menu {
    display: inline-block;
    position: relative;
  }
  
  .drop-down-menu__list {
    max-width: 200px;
    position: absolute;
    left: 0;
    box-shadow: 3px 3px 5px rgba(0,0,0,0.2);
    z-index: 1000;
    background: white;
    border: 1px solid #dddddd;
    margin-top: 5px;
  }
  
  .drop-down-menu__list-content {
    margin-bottom: 0;
  }
  
  .drop-down-menu__list-content__element {
    padding: 5px 10px;
    min-width: 100px;
    border-bottom: 1px solid #ddd;
  }
  
  .drop-down-menu__list-content__element:hover {
    cursor: pointer;
    background: #eeeeee;
  }
  
  .drop-down-menu__list-content__element:last-of-type {
    border-bottom: none;
  }
  