.login-form {
    padding-top: 100px;
  }
  
  .login-image{
    max-width: 100%;
  }
  
  .login-bg {
    /* Set rules to fill background */
    min-height: 100%;
    min-width: 1024px;
  
    /* Set up proportionate scaling */
    width: 100%;
    height: auto;
  
    /* Set up positioning */
    position: fixed;
    top: 0;
    left: 0;
  
    background: url('../../images/login-bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  
    z-index: -1;
  }
  